export default function scrolled() {
  const windowWidth = window.innerWidth;
  const body = document.querySelector('body');

  window.addEventListener('scroll', function(e) {
    if (windowWidth < 768) {
      if (window.scrollY >= 44) {
        body.classList.add('scrolled');
      } else if (window.scrollY < 44) {
        body.classList.remove('scrolled');
      }
    } else if (windowWidth >= 768 && windowWidth < 1280) {
      if (window.scrollY >= 72) {
        body.classList.add('scrolled');
      } else if (window.scrollY < 72) {
        body.classList.remove('scrolled');
      }
    } else if (windowWidth >= 1280) {
      if (window.scrollY >= 53) {
        body.classList.add('scrolled');
      } else if (window.scrollY < 53) {
        body.classList.remove('scrolled');
      }
    }
  });
}
